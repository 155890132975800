import React     from 'react'
import cx        from 'utils/classnames'
import {
    Container,
    Col,
    Row
}               from 'react-bootstrap'
import styles    from './styles.module.scss'


const CheckoutTerms = () => {
    return (
        <>
            <div className={styles['checkout-terms']}>
                <Container>
                    <Row>
                        <Col>
                            <p className={cx(styles['text-small'], 'mb-0')}>Additional terms to maybe say something about the tours or extras that we don't own eg Hobbiton / Jucy Cruise / Flybacks / Rotorua attractions?</p>
                            <p className={cx(styles['text-small'], 'mb-0')}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CheckoutTerms
